import React, { useState, useEffect } from 'react'


import { getBalanceAmount } from '../../../../api/public/wallet'

import { getUser } from '../../../../services/auth'
import { navigate } from 'gatsby'

import StarIcon from '@material-ui/icons/Star';
import Stack from '@mui/material/Stack';
import Typography from '@material-ui/core/Typography';

const VortexVoucherEwalletCheckoutButton = ({ onClick = () => { }, amount = 0 }) => {
	let [loading, toggleLoading] = useState(true)
	let [balance, setBalance] = useState(0)
	let [insufficientBalance, setInsufficientBalance] = useState(false)

	
	useEffect(() => {
		const user = getUser()

		getBalanceAmount(user.userId, user.token).then((data = []) => {
			console.log(data)
			if (data.error) {
				alert("Something happened fetching user balance, please try again")
			} else if (data.netAmount) {
				setBalance(data.netAmount)
				setInsufficientBalance(data.netAmount < amount)
				console.log(data.netAmount < amount)
			}
			toggleLoading(false)
		})

	}, [])

	return <div
		style={{
			display: "block",
			whiteSpace: "nowrap",

		}}
	>
		<button
			disabled={loading || insufficientBalance}
			style={{
				backgroundColor: loading ? "grey" : insufficientBalance ? 'grey' : '#0084FF',
				color: "white",
				borderRadius: '1em',
				display: "inline-block",
				textAlign: "center",
				height: "40px",
				position: "relative",
				width: "100%",
				overflow: "hidden",
				cursor: "pointer",
				border: 0,
			}}
			onClick={async () => {

				if (loading)
					return

				if (insufficientBalance)
					return navigate('/user/topup')

				toggleLoading(true)
				onClick()
			}}
		>
			<Stack direction="row" justifyContent='space-around'>
				<Stack direction="row">
					<StarIcon style = {{color: '#FFFF00'}}/>
					<Typography fontFamily = 'gothic'>Sparkle Blessings</Typography>
				</Stack>
				<Typography fontFamily = 'gothic'>
					PHP {balance}
				</Typography>
			</Stack>
		</button>
	</div>
}


export default VortexVoucherEwalletCheckoutButton