import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {primaryVortexTheme} from '../config/theme'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'


const VortexTopUpCountriesList = ({countries, onClick = () => {}}) => {
	console.log(countries)
	return (
	    <>
	      <Box>
	        <Typography
	          margin={2}
	          fontFamily={"Visby"}
	          fontSize={20}
	          color={"gray"}
	        >
	          Select Countries
	        </Typography>
	        {countries.map((country) => {
	          return (
	            <ListItem key={country} button onClick={() => {
			    onClick(country)
		    }}>
	              <Stack
	                direction={"row"}
	                alignItems="center"
	                width="100%"
	                margin="1em"
	              >
	                <Typography
	                  fontFamily={"Visby"}
	                  fontWeight={"bold"}
	                  style={{
	                    color: primaryVortexTheme.primarytextcolor,
	                  }}
	                >
	                  {country}
	                </Typography>
	              </Stack>
	              <ListItemIcon>
	                <ChevronRightIcon style={{ color: "#0060bf" }} />
	              </ListItemIcon>
	            </ListItem>
	          )
	        })}

	        <Divider />
	      </Box>
	    </>
	  )
}

export default VortexTopUpCountriesList