import React, { useState } from "react"
import Divider from "@material-ui/core/Divider"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { primaryVortexTheme } from "../config/theme"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

const VortexTopupCard = ({
  code = "W100",
  imageUrl = "https://via.placeholder.com/150",
  name = "Product Name",
  desc = "        ",
  price = 100.0,
  unit = "USD",
  onClick = () => {},
}) => {
  const [imgUrl, setImgUrl] = useState(imageUrl)
  return (
    <>
      <ListItem
        button
        onClick={() => {
          onClick()
        }}
      >
        {/* <ListItemIcon>
          <Avatar>{name.substring(0, 1)}</Avatar>
        </ListItemIcon> */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          width="100%"
          margin="1em"
        >
          <Stack direction = 'column' width = '24%'>
            <Typography
              margin={2} 
              fontFamily={"gothic"}
              fontWeight={700}
              style={{
                fontWeight: 'bold',
                margin: "0px",
                fontSize: "1.25em",
                color: '#0084FF',
              }}
            >
              {price.toFixed(0)}
            </Typography>
            <Typography
              textAlign={"start"}
              fontFamily={"gothic"}
              style={{
                fontWeight: 'bold',
                fontSize: "1em",
                color:'#0084FF',
              }}
            >
              {unit}
            </Typography>
          </Stack>
          <Stack direction = 'column' width = '90%' marginLeft={3}>
            <Typography
              fontFamily={"gothic"}
              fontWeight={"bold"}
              style={{
                color: primaryVortexTheme.primarytextcolor,
              }}
            >
              {name}
            </Typography>
            <Typography
              textAlign={"start"}
              fontSize={"12px"}
              color={primaryVortexTheme.primarytextcolor}
            >
              {desc}
            </Typography>
            {/* <Stack direction={"row"} spacing={1}>
            <Typography>{price}</Typography>
            <Typography>{unit}</Typography>
          </Stack> */}
          </Stack>
        </Stack>
        <ListItemIcon>
          <ChevronRightIcon style={{ color: "#0060bf" }} />
        </ListItemIcon>
      </ListItem>
      <Divider />
    </>
  )
}

export default VortexTopupCard
