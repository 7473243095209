import React, { useState, useEffect } from "react"
import ButtonBase from "@mui/material/ButtonBase"
import Card from "@mui/material/Card"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"


const VortexProductBrandCard = ({ title = "", image, onClick = () => { }, selected = false }) => {
  const [imgUrl, setImgUrl] = useState(image)

  useEffect(() => {
    let mounted = true
    console.log(imgUrl)

    if (mounted) {
      if (title === "ROW")
        setImgUrl(
          "https://upload.wikimedia.org/wikipedia/commons/0/0b/Blue_globe_icon.svg"
        )
      else if (title === "MERALCO" || title === "PLDTPH" || title === "TNTPH" || title === "SMARTPH" || title === "GLOBE") {
        console.log(`https://sparkle-vortex.imgix.net/${title}.png?w=60&h=60`)
        setImgUrl(`https://sparkle-vortex.imgix.net/${title}.png?w=120&h=120`)
      } else if (title === 'CIGNAL') {

        setImgUrl(`https://sparkle-vortex.imgix.net/${title}1.png?w=120&h=120`)
      }
    }

    return () => {
      mounted = false
    }
  }, [])
  return (
    <div>
      <div style={{
        margin: "0.7em",
        border: `0.2px solid ${selected ? '#0084FF' : 'grey'}`,
        borderRadius: '0.7em',
        backgroundColor: 'white',
        padding: '0.2em',
        boxShadow: `${selected ? 'inherit' : '0px 5px 5px 0px rgba(0,0,0,0.24)'}`
      }}>
        <ButtonBase
          onClick={() => {
            onClick()
          }}
        >
          <LazyLoadImage
            src={imgUrl}
            alt={"Vortex Brand Image"}
            width={80}
            height={80}
            onError={(e) => {
              console.log("asdfajsdfajkdjfs")
              console.log(e)
              setImgUrl(
                "https://upload.wikimedia.org/wikipedia/commons/0/0b/Blue_globe_icon.svg"
              )
            }}
          />
        </ButtonBase>
      </div>
    </div>
  )
}

export default VortexProductBrandCard
