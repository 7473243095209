import React from 'react'
import GcashLogo from '../../../../assets/images/gcash.webp'

const VortexTopupGcash = ({ onClick = () => { } }) => {
    return <div
        style={{
            display: "block",
            whiteSpace: "nowrap",
            marginTop: '0.5em'
        }}
    >
        <div
            style={{
                backgroundColor: "#0084FF",
                color: "white",
                borderRadius: "1em",
                display: "inline-block",
                textAlign: "center",
                height: "55px",
                position: "relative",
                width: "100%",
                overflow: "hidden",
                cursor: "pointer",
            }}
            onClick={onClick}>
            <img src={GcashLogo} height={50} />
        </div>

    </div>
}

export default VortexTopupGcash