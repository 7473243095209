import React from 'react'
import Page from '../../components/Vortex/pages/VortexTopUpPage'
import PageGradient from '../../components/PageGradient'

const VortexTopUp = () => {

    return <PageGradient>
        <Page />
    </PageGradient>
}

export default VortexTopUp